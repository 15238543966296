<template>
    <div class="static-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
      <AppHeader appName="main" :locale="locale"/>
      <main>
        <section class="sitemap">
            <div class="container-medium">
              <h1 class="main-title">{{$t('sitemap_header')}}</h1>
<!--TODO: nofollow for external links-->
              <div class="sitemap__grid">
                <div class="sitemap__item">
                  <h4>{{$t('sitemap_buy_insurance')}}</h4>
                  <ul>
                    <li><a :href="'/'+ locale +'/car-insurance'">{{$t('sitemap_car')}}</a></li>
                    <li><a :href="'/'+ locale +'/car-insurance/tpl'">{{ $t('sitemap_car_tpl') }}</a></li>
                    <li><a :href="'/'+ locale +'/car-insurance/comprehensive'">{{ $t('sitemap_car_comprehensive') }}</a></li>
                    <li><a :href="'/'+ locale +'/travel-insurance'">{{$t('sitemap_travel')}}</a></li>
                    <li><a :href="'/'+ locale +'/motorcycle-insurance'">{{$t('sitemap_moto')}}</a></li>
                    <li><a :href="'/'+ locale +'/boat-yacht-insurance'">{{$t('sitemap_boat')}}</a></li>
                    <li><a :href="'/'+ locale +'/home-insurance'">{{$t('sitemap_home')}}</a></li>
                    <li><a :href="'/'+ locale +'/personal-accident-insurance'">{{$t('sitemap_pab')}}</a></li>
                    <li><a :href="'/'+ locale +'/visitors'" target="_blank">{{ $t('sitemap_visitors') }}</a></li>
                    <!--li><a href="https://www.qic-insured.com/Online/SmeHomePage.do" target="_blank">{{ $t('sitemap_business_shield') }}</a></li>
                    <li><a href="https://www.qic-insured.com/Online/GolfInsurance.do" target="_blank">{{ $t('sitemap_golf') }}</a></li-->
                    <li><a :href="'/'+ locale +'/business-insurance'">{{$t('sitemap_business')}}</a></li>
                  </ul>
                </div>
                <div class="sitemap__item">
                  <h4>{{$t('sitemap_claims')}}</h4>
                  <ul>
                    <li><a :href="'/'+ locale +'/claims'">{{$t('sitemap_file_claim')}}</a></li>
                    <li><a :href="'/'+ locale +'/claims/motor'">{{ $t('sitemap_file_claim_motor') }}</a></li>
                    <li><a :href="'/'+ locale +'/claims/travel-claim'">{{ $t('sitemap_file_claim_travel') }}</a></li>
                    <li><a :href="'/'+ locale +'/claims/home-claim'">{{ $t('sitemap_file_claim_home') }}</a></li>
                    <li><a :href="'/'+ locale +'/claims/boat-claim'">{{ $t('sitemap_file_claim_boat') }}</a></li>
                    <li><a :href="'/'+ locale +'/claims/pab-claim'">{{ $t('sitemap_file_claim_pab') }}</a></li>
                    <li><a :href="'/'+ locale +'/claims/track'">{{ $t('sitemap_track_claim') }}</a></li>
                    <!--li><a href="https://www.auction.qic-insured.com/Auction/Home" target="_blank">{{ $t('sitemap_salvage_auction') }}</a></li-->
                  </ul>
                </div>
                <div class="sitemap__item">
                  <h4>{{$t('sitemap_support')}}</h4>
                  <ul>
                    <li><a :href="'/'+ locale +'/installment'">{{$t('sitemap_installment')}}</a></li>
                    <!--li><a :href="'https://www.qic-insured.com/QIC/Anoud/OnlineLogin.do?insuranceType=MP&usertype=Customer&request_locale='+ locale" target="_blank">{{ $t('sitemap_manage_policy') }}</a></li-->
                    <li><a :href="'/'+ locale +'/insurance-renewal'">{{$t('sitemap_renew_policy')}}</a></li>
                    <li><a :href="'/'+ locale +'/feedback'">{{$t('sitemap_feedback')}}</a></li>
                    <li><a :href="'/'+ locale +'/complaint'">{{$t('sitemap_complaint')}}</a></li>
                    <li><a :href="'/'+ locale +'/faq'">{{$t('sitemap_faq')}}</a></li>
                  </ul>
                </div>
                <div class="sitemap__item">
                  <h4>{{$t('sitemap_company')}}</h4>
                  <ul>
                    <li><a :href="'/'+ locale +'/about'">{{$t('sitemap_about')}}</a></li>
                    <li><a :href="'/'+ locale +'/history'">{{$t('sitemap_history')}}</a></li>
                    <li><a :href="'/'+ locale +'/contacts'">{{$t('sitemap_contact')}}</a></li>
                    <li><a :href="'/'+ locale +'/wordings'">{{$t('sitemap_wordings')}}</a></li>
                  </ul>
                </div>
              </div>
            </div>
        </section>
      </main>
      <AppFooter :locale="locale"/>
    </div>
</template>

<script>
import { AppHeader, AppFooter } from 'qic-components'
export default {
  name: 'Sitemap',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  components: {
    AppHeader,
    AppFooter
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
