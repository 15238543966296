<template>
<section id="landing-main" class="mainPage landing-main" :class="{ 'rtl': locale === 'ar' }">
  <AppHeader
    :locale="locale"
    app-name="main"
  />
    <main class="main-page">
      <LandingOffer :locale="locale" />
      <LandingAchievement/>
      <LandingFeature/>
      <LandingCta/>
      <LandingRecognitions/>
      <LandingInsure/>
      <LandingFeedback/>
      <LandingQicGroup />
    </main>
  <AppFooter
    :locale="locale"/>
</section>
</template>

<script>
import { AppHeader, AppFooter } from 'qic-components'
import LandingAchievement from './../components/LandingAchievement'
import LandingOffer from './../components/LandingOffer'
import LandingFeature from './../components/LandingFeature'
import LandingCta from './../components/LandingCta'
import LandingRecognitions from './../components/LandingRecognitions'
import LandingInsure from './../components/LandingInsure'
import LandingFeedback from './../components/LandingFeedback'
import LandingQicGroup from './../components/LandingQicGroup'

export default {
  name: 'MainPage',
  components: {
    LandingInsure: () => Promise.resolve(LandingInsure),
    LandingRecognitions: () => Promise.resolve(LandingRecognitions),
    LandingFeature: () => Promise.resolve(LandingFeature),
    LandingAchievement: () => Promise.resolve(LandingAchievement),
    LandingOffer: () => Promise.resolve(LandingOffer),
    LandingFeedback: () => Promise.resolve(LandingFeedback),
    LandingCta: () => Promise.resolve(LandingCta),
    LandingQicGroup: () => Promise.resolve(LandingQicGroup),
    AppFooter,
    AppHeader
  },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  methods: {
    onGetQuoteClick () {
      this.$router.push({ name: 'Home' })
    }
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
