<template>
    <div class="static-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
      <AppHeader appName="main" :locale="locale"/>
      <main>
        <section class="installment">
            <div class="container-medium">
              <h1 class="main-title">{{$t('installment_header')}}</h1>

              <div class="installment__grid">
                <div class="installment__item">
                  <h4>{{$t('installment_pay_header')}}</h4>
                  <p>{{$t('installment_pay_desc')}}</p>
                  <a v-keep-query class="btn btn-second_medium" :href="'/'+ locale +'/car-insurance/comprehensive'">{{$t('installment_pay_button')}}</a>
                </div>
                <div class="installment__item">
                  <h4>{{$t('installment_howitworks_header')}}</h4>
                  <p>{{$t('installment_howitworks_desc1')}}</p>
                  <p>{{$t('installment_howitworks_desc2')}}</p>
                  <p>{{$t('installment_howitworks_desc3')}}</p>
                  <p>{{$t('installment_howitworks_desc4')}}</p>
                  <p>{{$t('installment_howitworks_desc5')}}</p>
                  <a v-keep-query class="btn btn-second_medium" :href="'/'+ locale +'/car-insurance/comprehensive'">{{$t('installment_howitworks_button')}}</a>
                </div>
                <div class="installment__item">
                  <h4>{{$t('installment_aware_header')}}</h4>
                  <p><strong>{{$t('installment_aware_q1')}}</strong></p>
                  <p>{{$t('installment_aware_a1')}} <a :href="'/'+ locale +'/main//pdf/wordingsDocs/car_comp_installment.pdf'" target=_blank>{{$t('installment_aware_a1_terms')}}</a></p>
                  <p><strong>{{$t('installment_aware_q2')}}</strong></p>
                  <p>{{$t('installment_aware_a2')}}</p>
                </div>
              </div>
            </div>
        </section>
      </main>
      <AppFooter :locale="locale"/>
    </div>
</template>

<script>
import { AppHeader, AppFooter } from 'qic-components'
export default {
  name: 'Installment',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  components: {
    AppHeader,
    AppFooter
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
