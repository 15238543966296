<template>
    <div class="static-page landing-static" :class="{ 'rtl': locale === 'ar', 'thx': complaintSent, 'static-page': !complaintSent }">
        <AppHeader appName="main" :locale="locale"/>
          <section class="feedback-page" v-if="!loading">
            <div class="container">
              <h1 class="main-title">{{$t('complaint_header')}}</h1>
              <p class="sub-title">{{$t('complaint_desc')}}</p>
              <div class="inputList">
                <BaseInput
                  ref="inputFirstName"
                  :placeholder="$t('complaint_first_name')"
                  :validation="$v.firstName"
                  :value="firstName"
                  :maxlength="200"
                  @input="onInputFirstName($event)"/>
                <BaseInput
                  ref="inputFamilyName"
                  :validation="$v.familyName"
                  :placeholder="$t('complaint_last_name')"
                  :value="familyName"
                  :maxlength="200"
                  @input="onInputFamilyName($event)"/>
                <BaseInputPhone
                  :default-country-code="phoneInfo.countryCode"
                  :error="$v.phoneInfo.$dirty && $v.phoneInfo.$invalid"
                  :valid="!$v.phoneInfo.$invalid"
                  v-model="phoneInfo.phone"
                  inputMode="mini"
                  @update="onUpdatePhone" />
                <BaseInput
                  ref="inputEmail"
                  :validation="$v.email"
                  :placeholder="$t('complaint_email')"
                  :value="email"
                  :maxlength="200"
                  @input="onEmailInput"/>
                <BaseInput
                  ref="input"
                  :validation="$v.policy"
                  :mask="'N'.repeat(20)"
                  @change="(val) => { onInputPolicy(val) }"
                  :placeholder="$t('complaint_policy')"
                  v-model="policyNumber" />
                <p class="sub-title">{{$t('complaint_desc_second')}}</p>
                <BaseSelect
                style="z-index: 4;"
                v-model="complaintType.id"
                :options="complaintTypeList"
                :caret="true"
                :error="$v.complaintType.code.$dirty && $v.complaintType.code.$invalid"
                @input="onChangeType($event)"
                name="complaint"
                select-key="id"
                select-label="name"
                :placeholder="$t('complaint_type')"/>
                <BaseInput
                v-if="complaintType.id === 9"
                v-model="claimNo"
                :maxlength="20"
                :placeholder="$t('complaint_claims')"/>
                <BaseInput
                v-if="complaintType.id === 11"
                v-model="otherType"
                :placeholder="$t('complaint_other')"
                :maxlength="200"/>
                <div class="container-textarea" :class="{ error: $v.suggestion.$dirty && $v.suggestion.$invalid}">
                  <textarea class="textareaForm" placeholder=" " :value="suggestion" @input="onSuggestionInput" @focus="resizeTextArea" @keyup="resizeTextArea" maxlength="1000"></textarea>
                  <label for="Suggestion">{{$t('complaint_suggestion')}}</label>
                </div>
                </div>
                <div class="load-block">
                  <div v-for="(doc, index) in files" :key="index">
                    <div v-if="showButton(index)">
                      <UploadButton
                      :file="doc.file"
                      :id="doc.id"
                      :code="doc.code"
                      :maxFileSize="5"
                      @change="onChangeFile($event, index)"/>
                    </div>
                  </div>
                  <template v-if="!allFilesUploaded">
                    <p class="sub-title">{{$t('upload_btn_desc')}}</p>
                    <span class="uploadBtn__desc-size">{{$t('upload_btn_size')}}</span>
                  </template>
                  <p class="sub-title" v-else>{{$t('upload_btn_finish')}}</p>
                </div>
                <BaseButton symbol="no" @click="onSubmit">{{$t('complaint_submit')}}</BaseButton>
                <PrivPolicyLink with-offset />
                <div class="warning__help">
                  <div class="warning__help-title d-grid">
                    {{$t('complaint_help.item_1')}}
                    <span>{{$t('complaint_help.item_2')}}</span>
                    <span>{{$t('complaint_help.item_3')}}</span></div>
                  <div class="warning__help-desc">{{$t('complaint_contact_us')}}</div>
                  <a href="https://api.whatsapp.com/send/?phone=97450000742&amp;text&amp;app_absent=0" target="_blank" class="btnWhatsapp btnWhatsapp_big"><i class="wa"></i>{{$t('complaint_open_whatsapp')}}</a>
                  <a href="tel:8000742" class="btn btn-second btn-second_medium">{{$t('contacts_hotline')}}</a>
                </div>
            </div>
          </section>
          <Loader v-else/>
        <AppFooter :locale="locale"/>
    </div>
</template>

<script>
import { AppHeader, AppFooter, BaseInput, BaseButton, BaseInputPhone, Loader, BaseSelect } from 'qic-components'
import { required, email, alphaNum, maxLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import complaintFiles from '@/assets/maps/complaintFiles.json'
import UploadButton from '@/components/UploadButton.vue'
import PrivPolicyLink from '@/components/PrivPolicyLink.vue'

export default {
  name: 'ComplaintPage',
  components: {
    PrivPolicyLink,
    AppHeader,
    AppFooter,
    BaseInput,
    BaseButton,
    BaseInputPhone,
    Loader,
    BaseSelect,
    UploadButton
  },
  props: {
    value: String
  },
  data () {
    return {
      placeholder: ' ',
      complaintSent: false,
      firstName: '',
      familyName: '',
      phoneInfo: {
        countryCode: 'QA',
        phone: '',
        formattedPhone: '',
        isValidLength: false
      },
      email: '',
      policyNumber: '',
      suggestion: '',
      otherType: '',
      complaintType: {
        id: '',
        name: '',
        code: ''
      },
      claimNo: '',
      complaintTypeList: [],
      loading: false,
      finish: false,
      files: complaintFiles
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    allFilesUploaded () {
      return this.files.length === this.files.reduce((previousValue, currentValue) => {
        if (currentValue.file) return previousValue + 1
        return previousValue
      }, 0)
    }
  },
  methods: {
    ...mapActions([
      'sendComplaint'
    ]),
    onInputPolicy (val) {
      this.policyNumber = val.replace(/[^A-Za-z0-9]/g, '')
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    onSuggestionInput (value) {
      this.suggestion = value.target.value
      if (this.$v.suggestion) {
        this.$v.suggestion.$reset()
      }
    },
    resizeTextArea (e) {
      e.target.style.height = e.target.scrollHeight + 'px'
    },
    onInputFamilyName (value) {
      const regexp = /^([a-zA-Z_-\s']|[\u0621-\u064a])*$/
      this.familyName = regexp.test(value) ? value : this.familyName
      this.$refs.inputFamilyName.$refs.input.value = this.familyName
    },
    onInputFirstName (value) {
      const regexp = /^([a-zA-Z_-\s']|[\u0621-\u064a])*$/
      this.firstName = regexp.test(value) ? value : this.firstName
      this.$refs.inputFirstName.$refs.input.value = this.firstName
    },
    onUpdatePhone (result) {
      this.phoneInfo.formattedPhone = result?.formattedNumber()
      this.phoneInfo.isValidLength = result?.isValidLength()
      this.phoneInfo.countryCode = result?.countryCode
      this.$v.phoneInfo.$reset()
    },
    onEmailInput (value) {
      const regexp = /^([A-Za-z0-9._%+-@\s])*$/
      this.email = regexp.test(value) ? value : this.email
      this.$refs.inputEmail.$refs.input.value = this.email
    },
    onChangeType (id) {
      const type = this.complaintTypeList.find(list => list.id === id)
      this.complaintType.name = type.name
      this.complaintType.code = type.code
      if (this.$v.complaintType.code) {
        this.$v.complaintType.code.$reset()
      }
    },
    onChangeFile ({ file, docId }, fileId) {
      this.files[fileId].file = file
      this.files[fileId].id = docId
    },
    showButton (index) {
      const doc = this.files[index]
      const prevDocFullfild = index > 0 && (this.files[index - 1].file)
      return doc.file || index === 0 || prevDocFullfild
    },
    onSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.loading = true
      this.scrollToTop()
      const regexp = /^[\s-_']*((.*[^\s-_'])?)[\s-_']*$/
      this.sendComplaint({
        firstname: this.firstName.replace(regexp, '$1'),
        lastname: this.familyName.replace(regexp, '$1'),
        phone: this.phoneInfo.formattedPhone,
        email: this.email,
        policyNo: this.policyNumber,
        complaint: this.suggestion,
        typecode: this.complaintType.code,
        typename: this.complaintType.name,
        claimno: this.claimNo,
        topicname: this.otherType,
        file1: this.files[0].file,
        file2: this.files[1].file,
        file3: this.files[2].file
      })
        .then(() => {
          this.$router.push({ name: 'ThankYouPage' })
        })
        .finally(() => {
          this.complaintSent = true
          this.loading = false
        })
    }
  },
  mounted () {
    this.loading = true
    this.$store.dispatch('getComplaintTypeList')
      .then((types) => {
        this.complaintTypeList = types
        this.loading = false
      })
  },
  validations () {
    return {
      suggestion: {
        required
      },
      complaintType: {
        code: {
          required
        }
      },
      firstName: {
        required,
        validFormat: val => !/^[\s-_]+$/.test(val)
      },
      familyName: {
        required,
        validFormat: val => !/^[\s-_]+$/.test(val)
      },
      phoneInfo: {
        validPhone: () => this.phoneInfo.isValidLength || false
      },
      email: {
        required,
        email
      },
      policy: {
        alphaNum,
        maxLength: maxLength(20),
        validFormat: val => !/^[A-Za-z0-9]$/.test(val)
      }
    }
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
