<template>
    <div id="not-found" class="mainPage landing-static" :class="{ 'rtl': locale === 'ar' }">
        <AppHeader appName="main" :locale="locale"/>
        <section class="not-found">
          <h1>{{$t('qic_404_page')}}</h1>
          <p>404</p>
          <a :href="`/${locale}/`" class="btn btn-second">{{ $t('qic_error_go_home')}}</a>
        </section>
        <AppFooter :locale="locale"/>
    </div>
</template>

<script>
import { AppHeader, AppFooter } from 'qic-components'
export default {
  name: 'NotFound',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  components: {
    AppFooter,
    AppHeader
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>

<style scoped>
.rtl .btn, .rtl a.btn {
  padding: 0px 24px;
}
</style>
