<template>
  <div class="static-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
    <AppHeader appName="main" :locale="locale"/>
    <main>
      <Loader v-if="loading"/>
      <section v-else class="contacts">
        <div class="container-medium" ref="map_container">
          <h1 class="main-title">{{$t('contacts_header')}}</h1>
          <div v-if="isMobile" class="switchQuote">
            <div class="switchQuote__container">
              <input type="radio" name="policyPrice" id="map" value="map" v-model="mobileSwitch">
              <label for="map">{{$t('contacts_show_location')}}</label>
            </div>
            <div class="switchQuote__container">
              <input type="radio" name="policyPrice" id="list" value="list" v-model="mobileSwitch">
              <label for="list">{{$t('contacts_show_list')}}</label>
            </div>
          </div>
          <div class="contacts__map" v-show="!isMobile || mobileSwitch ==='map'">
            <GoogleMap
              ref="map"
              googleApiKey="AIzaSyAn_kmjt4UZTw8_hzjxjT07Zr1KjsRxy3Q"
              @afterInit="afterMapInit"/>
            <MapBranchPopup
              v-if="selectedBranch"
              :branchInfo="selectedBranch"
              :mapHref="selectedBranch.mapLink"
              @onClose="selectedBranch = null"
              />
          </div>

          <div class="contacts__blocks-grid">
            <div class="contacts__callback">
              <div class="contacts__callback-title">{{ $t('qic_need_help_popup_header') }}</div>
              <div class="contacts__callback-grid">
                <div class="contacts__callback-btn-group">
                  <div class="contacts__callback-desc">{{ $t('qic_need_help_popup_contact_wa') }}</div>
                  <a :href="whatsapUrl" target="_blank" class="btnWhatsapp">
                    <i class="wa"></i> {{ $t('qic_need_help_popup_wa_chat') }}
                  </a>
                  <a :href="`tel:${hotlinePhone}`" class="btn btn-second btn-second_medium">{{$t('contacts_hotline')}}</a>
                </div>
                <transition name="fade">
                  <div class="contacts__callback-phone" v-if="!callbackSent">
                    <div class="contacts__callback-desc">{{$t('contacts_request_call')}}</div>
                      <div class="contacts__callback-phone-grid">
                        <BaseInputPhone
                          :default-country-code="phoneNumberInfo.countryCode"
                          v-model="phoneNumberInfo.phone"
                          :error="$v.phoneNumberInfo.phone.$dirty && $v.phoneNumberInfo.phone.$invalid"
                          :valid="!$v.phoneNumberInfo.phone.$invalid"
                          @update="onUpdatePhone"
                          inputMode="mini"/>
                        <button class="btn btn_medium" type="submit" @click.prevent="onCallBackSend">
                          {{ $t('qic_send') }}
                        </button>
                      </div>

                    <PrivPolicyLink />
                  </div>
                </transition>
                <transition name="fade">
                  <div class="contacts__callback-phone contacts__callback-phone_success" v-if="callbackSent">
                    <img src="./../assets/images/thankyou.png" loading="lazy" alt="thank you qic">
                    <p> {{$t('business_we_will_call')}}</p>
                  </div>
                </transition>
              </div>
            </div>

            <div class="contacts__branches" v-show="!isMobile || mobileSwitch ==='list'">
              <h2>{{$t('contacts_branches')}}</h2>
              <ContactBranchItem
                v-for="(branch, index) of branches"
                :key="index"
                :branchInfo="branch"
                @onShowLocation="onShowLocation(branch)"
              />
            </div>
          </div>
        </div>
      </section>
    </main>
    <AppFooter :locale="locale"/>
  </div>
</template>

<script>
import { AppHeader, AppFooter, BaseInputPhone, Loader } from 'qic-components'
import ContactBranchItem from '@/components/ContactBranchItem'
import MapBranchPopup from '@/components/MapBranchPopup'
import GoogleMap from '@/components/GoogleMap'
import { isMobileOnly } from 'mobile-device-detect'
import { mapActions } from 'vuex'
import PrivPolicyLink from '@/components/PrivPolicyLink.vue'
// const GOOGLE_MAP_API_KEY = 'AIzaSyAOS9sothItfZEVOHKxi1fyXCLBg-aSF-E'

export default {
  name: 'Contacts',
  components: {
    PrivPolicyLink,
    AppHeader,
    AppFooter,
    BaseInputPhone,
    ContactBranchItem,
    MapBranchPopup,
    GoogleMap,
    Loader
  },
  inject: {
    errorHotlinePhone: { default: '8000742' },
    needHelpPhoneCode: { default: 'QA' },
    needHelpWhatsapNumber: { default: '97450000742' }
  },
  data () {
    return {
      phoneNumberInfo: {
        countryCode: this.needHelpPhoneCode,
        phone: '',
        formattedPhone: '',
        isValidLength: false
      },
      stateFilter: '',
      selectedBranch: null,
      showSent: false,
      branches: [],
      states: [],
      markers: [],
      callbackSent: false,
      loading: true,
      mobileSwitch: 'map'
    }
  },
  computed: {
    sendDisabled () {
      return this.$v.$invalid
    },
    whatsapUrl () {
      return `https://api.whatsapp.com/send/?phone=${this.needHelpWhatsapNumber}&text&app_absent=0`
    },
    hotlinePhone () {
      return String(this.errorHotlinePhone)
        .replaceAll(' ', '')
    },
    locale () {
      return this.$i18n.locale
    },
    filtredBranches () {
      if (!this.branches.length || !this.branches) return []
      // TODO: Filter by state was removed. Add filter if it is needed
      return this.branches
    },
    isMobile () {
      return isMobileOnly
    },
    currentMapHrefByLtLng () {
      const lat = this.selectedBranch.lat
      const lng = this.selectedBranch.lng
      return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    }
  },
  methods: {
    ...mapActions([
      'sendCallback'
    ]),
    onShowLocation (branch) {
      this.selectedBranch = branch
      this.stateFilter = branch.state
      this.mobileSwitch = 'map'
      this.centerGoogleMap({ lat: Number(branch.lat), lng: Number(branch.lng) })
      this.$refs.map_container.scrollIntoView({ behavior: 'smooth', inline: 'nearest' })
    },
    onShowSelectedLocation (branch) {
      this.selectedBranch = branch
      this.centerGoogleMap({ lat: Number(branch.lat), lng: Number(branch.lng) })
    },
    onUpdatePhone (result) {
      this.phoneNumberInfo.formattedPhone = result?.formattedNumber()
      this.phoneNumberInfo.isValidLength = result?.isValidLength()
      this.phoneNumberInfo.countryCode = result?.countryCode
      this.$v.phoneNumberInfo.phone.$reset()
    },
    clearAllGoogleMarkers () {
      this.markers.forEach(m => {
        this.$refs.map.deleteMarker(m)
      })
      this.markers = []
    },
    addAllGoogleMarkers () {
      this.filtredBranches.forEach(b => {
        const marker = this.$refs.map.addMarker({ lat: Number(b.lat), lng: Number(b.lng) })
        marker.addListener('click', () => this.onShowSelectedLocation(b))
        this.markers.push(marker)
      })
    },
    centerGoogleMap ({ lat, lng }) {
      this.$refs.map.setMapCenter({ lat, lng })
    },
    afterMapInit () {
      this.addAllGoogleMarkers()
      this.$refs.map.setBounds(this.markers, 9)
    },
    onCallBackSend () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.sendCallback({ phone: this.phoneNumberInfo.phone })
        .then(() => {
          this.callbackSent = true
          setTimeout(() => {
            this.callbackSent = false
            this.phoneNumberInfo.phone = null
          }, 5000)
        })
    }
  },
  mounted () {
    this.loading = true
    this.$store.dispatch('getBranchList')
      .then((branches) => {
        this.branches = branches
        this.states = [...this.branches.reduce((acc, branch) => {
          acc.add(branch.state)
          return acc
        }, new Set())]
        if (this.states.length) {
          this.stateFilter = this.states[0]
        }
        this.loading = false
      })
  },
  validations () {
    return {
      phoneNumberInfo: {
        phone: {
          validPhone: () => {
            return this.phoneNumberInfo.isValidLength
          }
        }
      }
    }
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>

<style lang="scss">
  .fade-enter-active {
    transition: all  1s;
  }
  .fade-enter {
    opacity: 0;
  }
</style>
