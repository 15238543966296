<template>
    <div class="static-page landing-static" :class="{ 'rtl': locale === 'ar', 'thx': feedbackSent, 'static-page': !feedbackSent }">
        <AppHeader appName="main" :locale="locale"/>
          <section class="feedback-page" v-if="!feedbackSent">
            <div class="container">
              <h1 class="main-title">{{$t('feedback_header')}}</h1>
              <p class="sub-title">{{$t('feedback_desc')}}</p>
              <div class="inputList">
                <BaseInput
                  ref="inputFirstName"
                  :placeholder="$t('feedback_first_name')"
                  :validation="$v.firstName"
                  :value="firstName"
                  @input="onInputFirstName($event)"/>
                <BaseInput
                  ref="inputFamilyName"
                  :validation="$v.familyName"
                  :placeholder="$t('feedback_last_name')"
                  :value="familyName"
                  @input="onInputFamilyName($event)"/>
                <BaseInputPhone
                  :default-country-code="phoneInfo.countryCode"
                  :error="$v.phoneInfo.$dirty && $v.phoneInfo.$invalid"
                  :valid="!$v.phoneInfo.$invalid"
                  v-model="phoneInfo.phone"
                  inputMode="mini"
                  @update="onUpdatePhone" />
                <BaseInput
                  ref="inputEmail"
                  :validation="$v.email"
                  :placeholder="$t('feedback_email')"
                  :value="email"
                  @input="onEmailInput"/>
                <div class="container-textarea" :class="{ error: $v.suggestion.$dirty && $v.suggestion.$invalid}">
                  <textarea class="textareaForm" placeholder=" " :value="suggestion" @input="onSuggestionInput"></textarea>
                  <label for="Suggestion">{{$t('feedback_suggestion')}}</label>
                </div>
                </div>
                <BaseButton symbol="no" @click="onSubmit">{{$t('feedback_submit')}}</BaseButton>
                <PrivPolicyLink with-offset />
                <div class="warning__help">
                  <div class="warning__help-title">{{$t('feedback_help')}}</div>
                  <div class="warning__help-desc">{{$t('feedback_contact_us')}}</div>
                  <a href="https://api.whatsapp.com/send/?phone=97450000742&amp;text&amp;app_absent=0" target="_blank" class="btnWhatsapp btnWhatsapp_big"><i class="wa"></i>{{$t('feedback_open_whatsapp')}}</a>
                  <a href="tel:8000742" class="btn btn-second btn-second_medium">{{$t('contacts_hotline')}}</a>
                </div>
            </div>
          </section>
          <Loader v-else/>
        <AppFooter :locale="locale"/>
    </div>
</template>

<script>
import { AppHeader, AppFooter, BaseInput, BaseButton, BaseInputPhone, Loader } from 'qic-components'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import PrivPolicyLink from '@/components/PrivPolicyLink.vue'

export default {
  name: 'FeedbackPage',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  components: {
    PrivPolicyLink,
    AppHeader,
    AppFooter,
    BaseInput,
    BaseButton,
    BaseInputPhone,
    Loader
  },
  props: {
    value: String
  },
  data () {
    return {
      placeholder: ' ',
      feedbackSent: false,
      firstName: '',
      familyName: '',
      phoneInfo: {
        countryCode: 'QA',
        phone: '',
        formattedPhone: '',
        isValidLength: false
      },
      email: '',
      suggestion: ''
    }
  },
  methods: {
    ...mapActions([
      'sendFeedback'
    ]),
    onSuggestionInput (value) {
      this.suggestion = value.target.value
      if (this.$v.suggestion) {
        this.$v.suggestion.$reset()
      }
    },
    onInputFamilyName (value) {
      const regexp = /^([a-zA-Z_-\s']|[\u0621-\u064a])*$/
      this.familyName = regexp.test(value) ? value : this.familyName
      this.$refs.inputFamilyName.$refs.input.value = this.familyName
    },
    onInputFirstName (value) {
      const regexp = /^([a-zA-Z_-\s']|[\u0621-\u064a])*$/
      this.firstName = regexp.test(value) ? value : this.firstName
      this.$refs.inputFirstName.$refs.input.value = this.firstName
    },
    onEmailInput (value) {
      const regexp = /^([A-Za-z0-9._%+-@\s])*$/
      this.email = regexp.test(value) ? value : this.email
      this.$refs.inputEmail.$refs.input.value = this.email
    },
    onUpdatePhone (result) {
      this.phoneInfo.formattedPhone = result?.formattedNumber()
      this.phoneInfo.isValidLength = result?.isValidLength()
      this.phoneInfo.countryCode = result?.countryCode
      this.$v.phoneInfo.$reset()
    },
    onSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.feedbackSent = true
      const regexp = /^[\s-_']*((.*[^\s-_'])?)[\s-_']*$/
      this.sendFeedback({
        firstname: this.firstName.replace(regexp, '$1'),
        lastname: this.familyName.replace(regexp, '$1'),
        phone: this.phoneInfo.formattedPhone,
        email: this.email,
        suggestion: this.suggestion
      })
        .then(() => {
          this.$router.push({ name: 'ThankYouPage' })
        })
        .finally(() => {
          this.feedbackSent = true
        })
    }
  },
  validations () {
    return {
      suggestion: {
        required
      },
      firstName: {
        required,
        validFormat: val => !/^[\s-_]+$/.test(val)
      },
      familyName: {
        required,
        validFormat: val => !/^[\s-_]+$/.test(val)
      },
      phoneInfo: {
        validPhone: () => this.phoneInfo.isValidLength || false
      },
      email: {
        required,
        email
      }
    }
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
