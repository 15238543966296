<template>
  <div id="not-found" class="mainPage landing-static" :class="{ 'rtl': locale === 'ar' }">
    <AppHeader appName="main" :locale="locale"/>
    <section class="not-found thank-you">
      <h1>{{$t('thank_you_header')}}</h1>
      <img src="./../assets/images/thankyou.png" loading="lazy" alt="thank you qic">
      <a :href="`/${locale}/`" class="btn btn-second">{{ $t('static_go_home')}}</a>
    </section>
    <AppFooter :locale="locale"/>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'qic-components'
export default {
  name: 'ThankYouPage',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  components: {
    AppFooter,
    AppHeader
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
