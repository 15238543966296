<template>
  <div class="static-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
    <AppHeader appName="main" :locale="locale"/>
    <main>
      <section class="download">
        <div class="container-medium">
          <h1 class="download__title">{{$t('download.title')}}</h1>
          <p class="download__description">{{$t('download.description')}}</p>
          <div class="download__links">
            <a
              href="https://apps.apple.com/qa/app/qd-drive-car-insurance-wallet/id1620043397"
              target="_blank"
              class="download__app-store">
              <IconAppStore :icon="locale === 'en' ? 'en' : 'ar'" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=app.qd.qatardrive"
              target="_blank"
              class="download__app-store">
              <IconGooglePay :icon="locale === 'en' ? 'en' : 'ar'"/>
            </a>
          </div>
          <img src="@/assets/images/download/phone-en.png" alt="mobile qd app" class="download__img download__img--en">
          <img src="@/assets/images/download/phone-ar.png" alt="mobile qd app" class="download__img download__img--ar">
        </div>
      </section>
    </main>
    <AppFooter :locale="locale"/>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'qic-components'
import IconAppStore from '@/components/icons/IconAppStore.vue'
import IconGooglePay from '@/components/icons/IconGooglePay.vue'
export default {
  name: 'Download',
  components: {
    IconGooglePay,
    IconAppStore,
    AppHeader,
    AppFooter
  },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>

<style lang="scss">
.download{
  text-align: center;

  &__title{
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #1C1C1C;
    margin-bottom: 12px;
  }

  &__description{
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1C1C1C;
    margin-bottom: 24px;
  }

  &__links{
    display: grid;
    width: max-content;
    grid-template-columns: repeat(2, max-content);
    gap: 12px;
    margin: 0 auto 24px;
  }

  &__img{
    max-width: 244px;
    width: 100%;
    margin-bottom: -66px;
    &--ar{
      display: none;
    }
  }
}

.rtl{
  .download{
    &__title{
      text-align: right;
    }
    &__description{
      text-align: right;
    }
    &__img{
      &--en{
        display: none;
      }

      &--ar{
        display: inline;
      }
    }
  }
}
</style>
