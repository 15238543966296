<template>
    <div class="static-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
        <AppHeader appName="main" :locale="locale"/>
        <main>
            <section class="business-ins">
                <div class="container-bp">
                  <h1 class="business-ins__title mobile">{{$t('business_header')}}</h1>
                  <p class="business-ins__desc mobile">{{$t('business_desc')}}</p>
                  <div class="business-ins__grid">
                    <div class="business-ins__content">
                      <h1 class="business-ins__title desk">{{$t('business_header')}}</h1>
                      <p class="business-ins__desc desk">{{$t('business_desc')}}</p>
                      <div class="business-ins__list">
                        <a v-keep-query :href="`/${locale}/business-shield`" class="business-ins__item">
                          <h4>{{$t('business_property_header')}}</h4>
                          <p>{{$t('business_property_desc')}}</p>
                          <a v-keep-query :href="`/${locale}/business-shield`">{{$t('business_learn')}}</a>
                        </a>
                        <a href="https://qic-group.com/marine-aviation/" target=_blank class="business-ins__item">
                          <h4>{{$t('business_marine_header')}}</h4>
                          <p>{{$t('business_marine_desc')}}</p>
                          <a href="https://qic-group.com/marine-aviation/" target="_blank">{{$t('business_learn')}}</a>
                        </a>
                        <a href="https://qic-group.com/motor-fleet/" target=_blank class="business-ins__item">
                          <h4>{{$t('business_motorfleet_header')}}</h4>
                          <p>{{$t('business_motorfleet_desc')}}</p>
                          <a href="https://qic-group.com/motor-fleet/" target="_blank">{{$t('business_learn')}}</a>
                        </a>
                        <a href="https://qic-group.com/energy/" target=_blank class="business-ins__item">
                          <h4>{{$t('business_energy_header')}}</h4>
                          <p>{{$t('business_energy_desc')}}</p>
                          <a href="https://qic-group.com/energy/" target="_blank">{{$t('business_learn')}}</a>
                        </a>
                      </div>
                    </div>
                    <div class="business-ins__form">
                      <Loader v-if="isSending"/>
                        <transition name="fade">
                          <div class="business-ins__form-inner" v-if="!formSent && !isSending">
                            <h5>{{$t('business_request_call')}}</h5>
                            <div class="business-ins__form-list">
                            <p class="business-ins__form-desc">{{$t('business_enter_contacts')}}</p>
                            <BaseInput
                              ref="inputName"
                              :placeholder="$t('business_full_name')"
                              inputStyle="big"
                              :validation="$v.name"
                              :value="name"
                              @input="onNameInput"/>
                            <BaseInputPhone
                              :default-country-code="phoneInfo.countryCode"
                              v-model="phoneInfo.phone"
                              :error="$v.phone.$dirty && $v.phone.$invalid"
                              :valid="!$v.phone.$invalid"
                              @update="onUpdatePhone"/>
                            </div>
                            <BaseButton symbol="no" @click.prevent="onSubmit">{{$t('business_submit')}}</BaseButton>
                            <PrivPolicyLink with-offset />
                          </div>
                        </transition>
                        <transition name="fade">
                          <div class="business-ins__form-inner business-ins__form-inner_thx" v-if="formSent && !isSending">
                            <h4>{{$t('business_thankyou')}}</h4>
                            <p>{{$t('business_we_will_call')}}</p>
                            <img src="./../assets/images/thankyou.png" loading="lazy" alt="thank you qic">
                          </div>
                        </transition>
                    </div>
                  </div>
                </div>
            </section>
        </main>
        <AppFooter :locale="locale"/>
    </div>
</template>

<script>
import
{
  AppHeader,
  AppFooter,
  BaseInput, BaseButton,
  BaseInputPhone,
  Loader
} from 'qic-components'

import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import PrivPolicyLink from '@/components/PrivPolicyLink.vue'
const nameClearReg = /^[\s-_']*((.*[^\s-_'])?)[\s-_']*$/

export default {
  name: 'BusinessInsurance',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  components: {
    PrivPolicyLink,
    AppHeader,
    AppFooter,
    BaseInput,
    BaseButton,
    BaseInputPhone,
    Loader
  },
  inject: {
    errorHotlinePhone: { default: '8000742' },
    needHelpPhoneCode: { default: 'QA' },
    needHelpWhatsapNumber: { default: '97450000742' }
  },
  data () {
    return {
      phoneInfo: {
        countryCode: this.needHelpPhoneCode,
        phone: '',
        name: '',
        formattedPhone: '',
        isValidLength: false
      },
      name: '',
      formSent: false,
      isSending: false
    }
  },
  methods: {
    ...mapActions([
      'sendCallback'
    ]),
    onSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const name = this.name.replace(nameClearReg, '$1')
      this.isSending = true
      this.sendCallback({ phone: this.phoneInfo.phone, name })
        .then(() => {
          this.formSent = true
          this.isSending = false
          setTimeout(() => {
            this.formSent = false
            this.name = ''
            this.phoneInfo.phone = ''
            this.$v.$reset()
          }, 5000)
        })
    },
    onUpdatePhone (result) {
      this.phoneInfo.formattedPhone = result?.formattedNumber()
      this.phoneInfo.isValidLength = result?.isValidLength()
      this.phoneInfo.countryCode = result?.countryCode
      this.$v.phone.$reset()
    },
    onNameInput (value) {
      const regexp = /^([a-zA-Z_-\s']|[\u0621-\u064a])*$/
      this.name = regexp.test(value) ? value : this.name
      this.$refs.inputName.$refs.input.value = this.name
    }
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  },
  validations () {
    return {
      phone: {
        validPhone: () => this.phoneInfo.isValidLength || false
      },
      name: {
        required,
        validFormat: val => !/^[\s-_]+$/.test(val)
      }
    }
  }
}
</script>

<style lang="scss">
  .fade-enter-active {
    transition: all  1s;
  }
  .fade-enter {
    opacity: 0;
  }
</style>
