<template>
  <div class="about-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
    <AppHeader appName="main" :locale="locale"/>
    <main>
      <AboutMenu />
      <div class="about-history">
        <h1>{{$t('about_history_title')}}</h1>
        <div class="about-history__list">
          <div class="about-history__item">
            <div class="about-history__item-year">1964</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_1964')}}</p>
              <EstablishedIcon />
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">1968</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_1968')}}</p>
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">2002</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_2002')}}</p>
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">2004</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_2004.text_1')}}</p>
              <p>{{$t('about_history_2004.text_2')}}</p>
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">2007</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_2007')}}</p>
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">2008</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_2008')}}</p>
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">2011</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_2011')}}</p>
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">2014</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_2014')}}</p>
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">2021</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_2021.text_1')}}</p>
              <p>{{$t('about_history_2021.text_2')}}</p>
              <p>{{$t('about_history_2021.text_3')}}</p>
              <p>{{$t('about_history_2021.text_4')}}</p>
              <p>{{$t('about_history_2021.text_5')}}</p>
              <BestSiteIcon/>
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">2022</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_2022.text_1')}}</p>
              <p>{{$t('about_history_2022.text_2')}}</p>
              <p>{{$t('about_history_2022.text_3')}}</p>
              <InsuranceBrandIcon/>
            </div>
          </div>

          <div class="about-history__item">
            <div class="about-history__item-year">2023</div>
            <div class="about-history__item-content">
              <p>{{$t('about_history_2023.text_1')}}</p>
              <p>{{$t('about_history_2023.text_2')}}</p>
              <p>{{$t('about_history_2023.text_3')}}</p>
              <p>{{$t('about_history_2023.text_4')}}</p>
              <p>{{$t('about_history_2023.text_5')}}</p>
              <p>{{$t('about_history_2023.text_6')}}</p>
              <p>{{$t('about_history_2023.text_8')}}</p>
              <p>{{$t('about_history_2023.text_9')}}</p>
              <p>{{$t('about_history_2023.text_11')}}</p>
              <p>{{$t('about_history_2023.text_12')}}</p>
              <p>{{$t('about_history_2023.text_13')}}</p>
              <p>{{$t('about_history_2023.text_14')}}</p>
              <p>{{$t('about_history_2023.text_15')}}</p>
              <p>{{$t('about_history_2023.text_16')}}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <AppFooter :locale="locale"/>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'qic-components'
import AboutMenu from '@/components/about/AboutMenu'
import EstablishedIcon from '@/components/about/assets/svg/EstablishedIcon'
import BestSiteIcon from '@/components/about/assets/svg/BestSiteIcon'
import InsuranceBrandIcon from '@/components/about/assets/svg/InsuranceBrandIcon'
export default {
  name: 'HistoryPage',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  components: {
    InsuranceBrandIcon,
    EstablishedIcon,
    BestSiteIcon,
    AppHeader,
    AppFooter,
    AboutMenu
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
