<template>
  <div class="about-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
    <AppHeader appName="main" :locale="locale"/>
    <main>
      <AboutMenu/>
      <AboutQic/>
      <AboutWhyQic/>
      <AboutWhatOffer/>
      <AboutAwards />
      <AboutLinks />
      <AboutShortFacts/>
    </main>
    <AppFooter :locale="locale"/>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'qic-components'
import AboutMenu from '@/components/about/AboutMenu'
import AboutQic from '@/components/about/AboutQic'
import AboutWhyQic from '@/components/about/AboutWhyQic'
import AboutWhatOffer from '@/components/about/AboutWhatOffer'
import AboutLinks from '@/components/about/AboutLinks'
import AboutShortFacts from '@/components/about/AboutShortFacts'
import AboutAwards from '@/components/about/AboutAwards'
export default {
  name: 'AboutPage',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  components: {
    AboutAwards,
    AboutQic,
    AboutWhyQic,
    AboutWhatOffer,
    AboutLinks,
    AboutShortFacts,
    AppHeader,
    AppFooter,
    AboutMenu
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
