import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.VUE_APP_API_URL || '',
  // timeout: 60 * 1000, // Timeout
  withCredentials: true // Check cross-site Access-Control
}
// console.log(config)
const httpClient = axios.create(config)

httpClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
httpClient.interceptors.response.use(
  function (response) {
    if (response.data && response.data.success !== undefined && !response.data.success) {
      throw new Error(response.data.errorMessage || 'no_error_message')
    }

    return response
  },
  function (error) {
    console.error(error)
    throw new Error('network_error')
  }
)

export default httpClient
