import '@/assets/css/style.scss'
import 'simplebar/dist/simplebar.css'

import 'animate.css'
import 'simplebar'
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
// import './registerServiceWorker'
import Vuelidate from 'vuelidate'
import router from './router'
import i18n from './i18n'
import store from './store'
import VueMask from 'v-mask-persian'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import getAppendQueryDirective from './directives/getAppendQueryDirective'

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(VueMeta)
Vue.use(VueScrollTo)

Vue.directive('keepQuery', getAppendQueryDirective(['utm_']))

Vue.filter('splitNumber', (value) => value ? value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ') : '')

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
