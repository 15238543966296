<template>
    <div class="static-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
        <AppHeader appName="main" :locale="locale"/>
        <main>
            <section class="wordings">
                <div class="container-medium">
                    <h1 class="main-title">{{$t('wordings_header')}}</h1>

                    <div class="wordings__grid">
                        <div class="wordings__item">
                            <h3>{{$t('wordings_car_header')}}</h3>
                            <ul class="wordings__item-list">
                                <li><a :href="getPdfUrl('car_tpl')" target="_blank">{{$t('wordings_car_tpl')}}</a></li>
                                <li><a :href="getPdfUrl('car_comp')" target="_blank">{{$t('wordings_car_od')}}</a></li>
                                <li><a :href="getPdfUrl('car_comp_installment')" target="_blank">{{$t('wordings_car_od_installment')}}</a></li>
                                <li><a :href="getPdfUrl('car_agencyrepair')" target="_blank">{{$t('wordings_car_agency_repair')}}</a></li>
                                <li><a :href="getPdfUrl('car_emergency_roadside')" target="_blank">{{$t('wordings_car_emergency_roadside')}}</a></li>
                                <li><a :href="getPdfUrl('car_gap_cover')"  target="_blank">{{$t('wordings_car_gap_cover')}}</a></li>
                                <li><a :href="getPdfUrl('car_gcc')" target="_blank">{{$t('wordings_car_gcc')}}</a></li>
                                <li><a :href="getPdfUrl('car_no_deprication')" target="_blank">{{$t('wordings_car_no_depreciation')}}</a></li>
                                <li><a :href="getPdfUrl('car_off_road')" target="_blank">{{$t('wordings_car_off_road')}}</a></li>
                                <li><a :href="getPdfUrl('car_off_road_360')" target="_blank">{{$t('wordings_car_off_road_360')}}</a></li>
                                <li><a :href="getPdfUrl('car_policy_accident')" target="_blank">{{$t('wordings_car_policy_accident')}}</a></li>
                                <li><a :href="getPdfUrl('car_replacement')" target="_blank">{{$t('wordings_car_replacement')}}</a></li>
                                <li><a :href="getPdfUrl('car_riot')" target="_blank">{{$t('wordings_car_riot')}}</a></li>
                                <li><a :href="getPdfUrl('car_unknowm_damage')" target="_blank">{{$t('wordings_car_unknown_damage')}}</a></li>
                            </ul>
                        </div>
                        <div class="wordings__item">
                            <h3>{{$t('wordings_travel_header')}}</h3>
                            <ul class="wordings__item-list">
                                <li><a :href="getPdfUrl('travel_inbound')" target="_blank">{{$t('wordings_travel_insurance')}}</a></li>
                                <li><a :href="getPdfUrl('travel_outbound')" target="_blank">{{$t('wordings_travel_outbound')}}</a></li>
                                <li><a :href="getPdfUrl('travel_winter')" target="_blank">{{$t('wordings_travel_winter')}}</a></li>
                                <li><a :href="getPdfUrl('travel_covid19')" target="_blank">{{$t('wordings_travel_covid19')}}</a></li>
                            </ul>
                        </div>
                        <div class="wordings__item">
                            <h3>{{$t('wordings_boat_header')}}</h3>
                            <ul class="wordings__item-list">
                                <li><a :href="getPdfUrl('boat_tpl')" target="_blank">{{$t('wordings_boat_tpl')}}</a></li>
                                <li><a :href="getPdfUrl('boat_od')" target="_blank">{{$t('wordings_travel_od')}}</a></li>
                            </ul>
                        </div>
                        <div class="wordings__item">
                            <h3>{{$t('wordings_home_header')}}</h3>
                            <ul class="wordings__item-list">
                                <li><a href="/pdf/Home_Terms_And_Conditions.pdf" target="_blank">{{$t('wordings_home_insurance')}}</a></li>
                            </ul>
                        </div>
                        <div class="wordings__item">
                            <h3>{{$t('wordings_pab_header')}}</h3>
                            <ul class="wordings__item-list">
                                <li><a :href="getPdfUrl('pab_insurance')" target="_blank">{{$t('wordings_pab_benefit')}}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <AppFooter :locale="locale"/>
    </div>
</template>

<script>
import { AppHeader, AppFooter } from 'qic-components'
export default {
  name: 'WordingsPage',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  components: {
    AppHeader,
    AppFooter
  },
  methods: {
    getPdfUrl (doc) {
      return `${process.env.BASE_URL}pdf/wordingsDocs/${doc}.pdf`
    }
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
